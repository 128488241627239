.ffTermsAndCondition{
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }
    ul li{
        list-style: disc;
    }
    li{
        font-size: 14px;
        line-height: 17px;
        margin: 0 0 10px;
        color: #222;
    }
    p{
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 10px;
        color: #222;
    }
    h1{
        font-weight: 800;
        margin-bottom: 20px;
    }
    h2, h3{
        margin-bottom: 10px;
        color: #222;
    }
    a,  a:focus, a:hover {
        color: #ca257b;
    }
    .ffListLicence li{
        list-style: lower-alpha;
    }
    .ffListCopyRight li{
        list-style: auto;
        ul{
           li {
                list-style: lower-alpha;
            }
        }
    }
}
