.ffNavbarMobileOverlay {
    width: 100%;
    background: rgba(0, 0, 0, 0.50);
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    display: none;
    height: 100%;
    .ffNavbarMobileInner {
        height: 100vh;
        background: #ca257b;
        box-shadow: 2px -1px 8px 0px rgba(0, 0, 0, 0.55);
        width: 323px;
        flex-shrink: 0;
        position: absolute;
        top: 0;
        left: 0; 
        .ffNavbarMobileTop {
            width: 100%;
            padding: 28px 30px;
            height: calc(100vh - 77px);
            overflow-y: auto;
            overflow-x: hidden;
            .ffNavbarMobileHead {
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: relative;
                margin-bottom: 30px;
                .ffNavbarMobileClose {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                }
            }
            .ffNavbarMobileSearch {
                border-radius: 4px;
                background: #EFEFEF;
                display: flex;
                width: 100%;
                padding: 10px;
                justify-content: space-between;
                align-items: center;
                height: 44px;
                margin-bottom: 20px;
                input {
                    color: #444;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    height: 100%;
                    border: 0 none;
                    background: none;
                    width: calc(100% - 24px);
                    &:focus {
                        outline: none;
                    }
                }
                a {
                    width: 24px;
                    height: 24px;
                    flex-shrink: 0; 
                    display: block;
                }
            }
            .ffNavbarMobileList {
                width: 100%;
                ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 25px;
                    li {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        align-content: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        a {
                            color: #FFF;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            display: flex;
                            justify-content: space-between;
                            padding-left: 18px;
                            border-left: 3px solid transparent;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            &.selected {
                                border-left: #fff solid 3px;
                            }
                        }
                        ul {
                            padding-left: 10px;
                            padding-top: 32px;
                            display: none;
                        }
                        &:hover {
                            ul {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
        .ffNavbarMobileBottom {
            background: #09131C; 
            width: 100%;
            height: 77px;
            flex-shrink: 0; 
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            gap: 24px;
            .ffNavbarMobileAccount {
                width: 28px;
                height: 28px;
                flex-shrink: 0; 
            }
            .ffNavbarMobileReg {
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}

