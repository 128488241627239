.ffProfileBox {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.10);
  flex-direction: column;
  align-items: center;
  gap: 12px; 
  margin-bottom: 24px;
  overflow: hidden;
  .ffProfilePic {
    width: 100%;
    height: 200px; 
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .ffProfileDesc {
    width: 100%;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    h2 {
      color: #222;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
    }
    p {
      overflow: hidden;
      color: #444;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 12px;
    }
    a {
      color: #0F71F2;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.ffMainContent {
  &.darkTheme {
    .ffBreadCrums {
      ul {
        li {
          a {
            color: #E8E8E8;
          }

          label {
            color: #FFF;
          }
        }
      }
    }
  }
}
