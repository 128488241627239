.ffNavbarSection {
    width: 100%;
    background: #000;
    height: 52px;
    @media (max-width:991px) {
        display: none;
    }
    .ffContainer {
        height: 100%;

        .ffNavbarNav {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 100%;
            align-items: center;
            align-content: center;
            .ffNavbarNavList {
                ul {
                    display: flex;
                    gap: 4px;
                    li {
                        position: relative;

                        a {
                            border-radius: 100px;
                            background: #571035;
                            display: block;
                            display: flex;
                            height: 34px;
                            padding: 8px 12px 8px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 6px;
                            color: #FFF;
                            text-align: center;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                            &.active {
                                background: #ca257b;
                                font-weight: 600;
                            }
                        }

                        .ffNavbarNavListDropDown {
                            position: absolute;
                            top: 30px;
                            left: 0;
                            background: #571035;
                            border-radius: 0 0 8px 8px;
                            padding: 14px;
                            flex-wrap: wrap;
                            width: 100%;
                            min-width: 120px;
                            display: none;
                            z-index: 2;
                            li {
                                width: 100%;

                                a {
                                    padding: 0;
                                    height: auto;
                                    text-align: left;
                                    justify-content: flex-start;
                                }
                            }
                        }

                        &.dropDownOtr {
                            &:hover {
                                a {
                                    border-radius: 8px 8px 0px 0px;
                                }

                                .ffNavbarNavListDropDown {
                                    display: flex;
                                    gap: 18px;
                                }
                            }
                        }
                    }
                }
            }

            .ffSocialNavList {
                height: 100%;

                ul {
                    display: flex;
                    gap: 24px;
                    height: 100%;
                    align-items: center;
                    align-content: center;

                    li {
                        &:first-child {
                            margin-bottom: -5px;
                        }

                        &:nth-child(2) {
                            svg {
                                width: 19px;
                                height: 18px;
                            }
                        }

                        &:nth-child(3) {
                            svg {
                                width: 19.5px;
                                height: 16px;
                            }
                        }

                        &:nth-child(4) {
                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}