body {
  font-family: var(--font-roboto), sans-serif;
  background: #EFEFEF;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

a {
  text-decoration: none;
  color: #222;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

li {
  list-style: none;
}

ul,
label,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.ffMainContent {
  // background: #EFEFEF;
  width: 100%;
  // padding-top: 20px;
  // padding-bottom: 20px;
  @media (max-width:991px) {
    background: #fff;
  }
  &.darkTheme {
    background: #731546;
    .ffMainContentInner {
      background: #ca257b;
    }
    a {
      color: #FFFFFF;
    }
  }
}
.ffMainContentInner {
  border-radius: 8px;
  background: #FFF;
  width: 100%;
  padding: 20px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin: 20px 0;
  @media (max-width:991px) {
    flex-wrap: wrap;
    padding: 0;
  }
}
.ffListingPageHeading{
  color: #222;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  margin-bottom: 12px;
}
.ffContainer {
  max-width: 1110px;
  padding: 0 15px;
  margin: auto;
}
@media (max-width: 991px) {
  .ffContainer {
    padding: 0 8px;
  }
}
.ffLeftContent {
  flex-grow: 1;
  width: calc(100% - 384px);
  @media (max-width:991px) {
    width: 100%;
  }
}
.ffSideBar {
  width: 359px; 
  flex-shrink: 0;
  @media (max-width:991px) {
    width: 100%;
  }
}

.hidden{
  display: none;
}
.show{
  display: block;
}