.ffHomeSearchBar {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background: #EFEFEF;
    height: 44px;
    margin-bottom: 20px;
    form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    @media (max-width:991px) {
      display: none;
    }
    button, input {
        border: 0 none;
        background: none;
    }
    svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      cursor: pointer;
    }
    input {
      width: calc(100% - 24px);
      height: 100%;
      border: 0 none;
      background: none;
      &:focus {
        outline: none;
      }
    }
  }
  
.ffMainContent {
    &.darkTheme {
      .ffMainContentInner {
        background: #ca257b;
        .ffHomeSearchBar {
          background: #731546;
          input {
            color: #FFF;
          }
          svg {
            fill:#808080;
          }
        }
        
      }
    }
  } 