.ffHeader {
    width: 100%;
    .ffHeaderTop {
        width: 100%;
        flex-shrink: 0; 
        background: #ca257b;
        padding-top: 16px;
        padding-bottom: 16px;
        @media (max-width:991px) {
            padding-top: 13px;
            padding-bottom: 13px;
        }
        .rasHeaderNav {
            width: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            .ffHeaderMenuIcon {
                width: 24px;
                height: 24px;
                flex-shrink: 0; 
                display: none;
                @media (max-width:991px) {
                    display: block;
                } 
            }
            .ffHeaderLogo {
                width: 165px;
                height: 30.118px;
                flex-shrink: 0; 
                display: block;
            }
            .ffHeaderBtns {
                ul {
                    display: flex;
                    gap: 16px;
                    @media (max-width:991px) {
                        gap: 0;
                    }
                    li {
                       .ffHeaderLoginBtn {
                            border-radius: 100px;
                            border: 1px solid #FFF;
                            width: 67px;
                            height: 32px;
                            flex-shrink: 0; 
                            color: #FFF;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal; 
                            display: flex;
                            align-items: center;
                            align-content: center;
                            justify-content: center;
                            @media (max-width:991px) {
                                display: none;
                            }
                       } 
                       .ffHeaderSignUpBtn {
                            border-radius: 100px;
                            width: 81px;
                            height: 32px;
                            flex-shrink: 0;
                            background: #FFF;
                            color: #ca257b;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            align-items: center;
                            align-content: center;
                            justify-content: center;
                            @media (max-width:991px) {
                                display: none;
                            }
                       }
                    }
                }
            }
        }
    }
}
.switch_outer {
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    .switch {
        position:relative;
        display:inline-block;
        width:100px;
        height:32px;
        input {
            opacity:0;
            width:0;
            height:0
        }
    }
    .slider {
        position:absolute;
        cursor:pointer;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border-radius: 71.111px;
        background: #ad2069; 
        -webkit-transition:.4s;
        transition:.4s;
        display: inline-flex;
        padding: 5px 5px 5px 9px;
        justify-content: flex-end;
        align-items: center;
        gap: 6px; 
        i {
            border-radius: 80px;
            background: #000;
            width: 22px;
            height: 22px;
        }
        &:before {
            position:absolute;
            content:"Dark";
            height:32px;
            width:55%;
            left:1px;
            bottom:0;
            background:transparent;
            transition:.4s;
            background-image:url('/assets/images/moon_pf1.png');
            background-repeat:no-repeat;
            background-position:5px center;
            color:#ccccce;
            background-size:18px 18px;
            padding-left:27px;
            display:flex;
            align-content:center;
            align-items:center;
            justify-content: flex-start;
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
        }
    }
  }
  .switch_outer>span {
    display: block;
    height: 32px;
  }
  .switch_outer input:checked+.slider {
    background: #ad2069; 
  }
  .switch_outer input:focus+.slider {
    box-shadow:0 0 0 transparent
  }
  .switch_outer input:checked+.slider:before {
    content:"Light";
    -webkit-transform:translateX(44px);
    -ms-transform:translateX(44px);
    transform:translateX(44px);
    height:32px;
    width:55%;
    right:1px;
    bottom:0;
    background:transparent;
    transition:.4s;
    background-image:url('/assets/images/sun_pf1.png');
    background-repeat:no-repeat;
    background-position: 90% center;
    color:#ccccce;
    background-size:18px 18px;
    padding-right:32px;
    padding-left:0;
    display:flex;
    align-content:center;
    align-items:center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
  .switch_outer .slider.round {
    border-radius:34px
  }
  .switch_outer input:checked ~ .slider {
    justify-content: flex-start;
  }
  @media screen and (max-width:1023px) {
    .switch_outer .switch {
        width: 60px;
        height: 32px; 
    }
    .switch_outer .slider::before {
      font-size:0;
      padding-left:20px
    }
    .switch_outer input:checked+.slider::before {
      font-size:0;
      transform:translateX(22px)
    }
  }
  