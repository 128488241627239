.ffAuthorSec {
  width: 100%;
  margin-bottom: 24px;
  h2 {
    color: #222;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 14px;
    width: 100%;
  }
  .ffAuthorBox {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.10);
    overflow: hidden;
    .ffAuthorRow {
      width: 100%;
      display: flex;
      .ffAuthorPic {
        width: 180px;
        height: 180px;
        flex-shrink: 0; 
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .ffAuthordetail {
        padding: 12px 16px;
        width: calc(100% - 180px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        h2 {
          color: #222;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 100%;
          margin-bottom: 16px;
        }
        p {
          color: #222;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          width: 100%;
        }
        h3 {
          color: #222;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 100%;
          margin-bottom: 13px;
        }
        a {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          margin-right: 13px;
        }
      }
    }
    .ffAuthorDesc {
      width: 100%;
      padding: 8px 16px;
      background: #FFF;
      p {
        color: #222;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.ffMainContent {
  &.darkTheme {
    .ffAuthorSec {
      h2 {
        color: #fff;
      }
      .ffAuthorBox {
        .ffAuthordetail {
          background: #731546;
          p {
            color: #fff;
          }
          h2 {
            color: #fff;
          }
          h3 {
            color: #fff;
          }
          a {
            svg {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
        .ffAuthorDesc {
          background: #731546;
          p {
            color: #fff;
          }
        }
      }
    }
  }
}