.ffBreadCrums {
  width: 100%;
  @media (max-width:991px) {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
  }
  ul {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;

    li {
      a {
        color: #595959;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
      }

      label {
        color: #ca257b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.ffMainContent {
  &.darkTheme {
    .ffBreadCrums {
      ul {
        li {
          a {
            color: #E8E8E8;
          }

          label {
            color: #FFF;
          }
        }
      }
    }
  }
}