
.ffHomeFeatures{
  border-radius: 8px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.10);
  width: 100%;
  margin-bottom: 24px;
  .ffHomeFeaturesPic {
    width: 100%;
    height: auto;
    background: #fff;
    @media (max-width:991px) {
      height: auto;
    }
    img {
      width: 100%;
      height: auto;
      @media (max-width:991px) {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .ffHomeFeaturesTitle {
    border-bottom: 1px solid #E8E8E8;
    padding: 0 20px;
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 16px;
    flex-wrap: wrap;
    @media (max-width:991px) {
      padding: 0 18px;
      gap: 12px;
      min-height: 36px;
    }
    label {
      color: #222;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width:991px) {
        font-size: 14px;
      }
    }
    span {
      color: #595959;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width:991px) {
        font-size: 14px;
      }
    }
  }
  .ffHomeFeaturesDesc {
    width: 100%;
    padding: 16px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px 4px;
    @media (max-width:991px) {
      padding: 12px 18px;
    }
    h2 {
      color: #222;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
      @media (max-width:991px) {
        font-size: 16px; 
      }
    }
    p {
      color: #444;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
      @media (max-width:991px) {
        font-size: 14px;  
      }
    }
    label {
      border-radius: 4px;
      background: #EFEFEF;
      display: flex;
      padding: 4px 12px;
      align-items: flex-start;
      gap: 10px;
      color: #161E26;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.ffSubFeatures {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .ffSubFeaturesBox{
    border-radius: 8px;
    border: 1px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.10);
    width: 48%;
    @media (max-width:991px) {
      width: 100%;
    }
    .ffSubFeaturesPic {
      width: 100%;
      height: 178px;
      flex-shrink: 0; 
      background: #fff;
      @media screen and (max-width:991px) {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .ffSubFeaturesTitle {
      border-bottom: 1px solid #E8E8E8;
      padding: 0 20px;
      min-height: 36px;
      width: 100%;
      display: flex;
      align-items: center;
      align-content: center;
      gap: 12px;
      flex-wrap: wrap;
      @media (max-width:991px) {
        padding: 0 18px;
      }
      label {
        color: #222;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        a {
          @media screen and (max-width:991px) {
            padding: 1px;
          }
        }
      }
      span {
        color: #595959;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .ffSubFeaturesDesc {
      width: 100%;
      padding: 16px 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px 4px;
      @media (max-width:991px) {
        padding: 12px 18px;
      }
      a {
        @media screen and (max-width:991px) {
          padding: 1px;
        }
      }
      h2 {
        color: #222;
        font-size: 16px; 
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
      }
      p {
        color: #444;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
      }
      label {
        border-radius: 4px;
        background: #EFEFEF;
        display: flex;
        padding: 4px 12px;
        align-items: flex-start;
        gap: 10px;
        color: #161E26;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.ffLeftContentAd {
  display: flex;
  width: 100%;
  height: 120px;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.4;
  background: #EFEFEF;
  margin-bottom: 30px;
  @media (max-width:991px) {
    background: #C4C4C4;
    font-weight: 600;  
    height: 60px; 
  }
}
.ffMoreNews {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.10);
  display: flex;
  align-items: center;
  align-content: center;
  padding: 5px;
  height: 150px;
  flex-shrink: 0; 
  gap: 16px;
  margin-bottom: 16px;
  @media (max-width:991px) {
    height: 120px; 
    gap: 10px;
  }
  .ffMoreNewsPic {
    width: 248px;
    height: 140px;
    flex-shrink: 0;
    border-radius: 6px; 
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width:991px) {
      width: 168px;
      height: 104px;
      flex-shrink: 0; 
    }
  }
  .ffMoreNewsRight {
    width: calc(100% - 248px);
    @media (max-width:991px) {
      width: calc(100% - 168px);
    }
    .ffMoreNewsDescTitle {
      width: 100%;
      display: flex;
      align-items: center;
      align-content: center;
      gap: 8px;
      margin-bottom: 8px;
      @media (max-width:991px) {
        margin-bottom: 4px;
      }
      label {
        color: #222;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width:991px) {
          font-size: 10px;
        }
      }
      span {
        color: #686E7F;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width:991px) {
          font-size: 10px;
        }
      }
    }
    .ffMoreNewsDesc {
      gap: 8px 4px;
      display: flex;
      flex-wrap: wrap;
      @media (max-width:991px) {
        gap: 4px 4px;
      }
      h2 {
        color: #222;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:wrap;
        @media (max-width:991px) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          width: auto;
          white-space: normal;
        }
      }
      p {
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @media (max-width:991px) {
          display: none;
        }
      }
      label {
        border-radius: 4px;
        background: #EFEFEF;
        display: flex;
        padding: 4px 12px;
        align-items: flex-start;
        gap: 10px;
        color: #161E26;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        @media (max-width:991px) {
          font-size: 10px;
        }
      }
    }
  }
}
.ffMoreNewsHead {
  width: 100%;
  h1 {
    color: #222;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 14px;
  }
}
.ffMoreBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #ca257b;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 100px;
    border: 2px solid #ca257b;
    width: 134px;
    height: 44px;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
  }
}
.ffMainContent {
  &.darkTheme {
    .ffMainContentInner {
      background: #ca257b;
      .ffHomeFeatures {
        background: #731546;
        border: #731546 solid 1px;
        overflow: hidden;
        .ffHomeFeaturesPic {
          background: #731546;
        }
        .ffHomeFeaturesTitle {
          border-bottom: #571035 solid 1px;
          label {
            color: #FFF;
          }
          span {
            color: #595959; 
          }
        }
        .ffHomeFeaturesDesc {
          h2 {
            color: #FFF;
          }
          p {
            color: #FFF;
          }
          label {
            background: #ca257b;
            color: #FFF;
          }
        }
      }
      .ffSubFeaturesBox {
        background: #731546;
        border: #731546 solid 1px;
        .ffSubFeaturesPic {
          background: #731546;
        }
        .ffSubFeaturesTitle {
          border-bottom: #571035 solid 1px;
          label {
            color: #FFF;
          }
          span {
            color: #595959; 
          }
        }
        .ffSubFeaturesDesc {
          h2 {
            color: #FFF;
          }
          p {
            color: #FFF;
          }
          label {
            background: #ca257b;
            color: #FFF;
          }
        }
      }
      .ffMoreNews {
        background: #731546;
        border: #731546 solid 1px;
        .ffMoreNewsDescTitle {
          label {
            color: #FFF;
          }
          span {
            color: #595959; 
          }
        }
        .ffMoreNewsDesc {
          h2 {
            color: #FFF;
          }
          p {
            color: #FFF;
          }
          label {
            background: #ca257b;
            color: #FFF;
          }
        }
      }
    }
  }
} 