.featured-shimmer-box {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
}
.sub-featured-shimmer-box {
  width: 48%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.shimmer-wrapper {
  width: 100%;
}

.shimmer {
  background: linear-gradient(to right, #e0e0e0 0%, #f0f0f0 50%, #e0e0e0 100%);
  animation: shimmer 2s infinite;
  border-radius: 4px;
  margin-bottom: 2px;
}

.shimmer.featured-image-placeholder {
  width: 100%;
  height: 368px;
}
.shimmer.sub-featured-image-placeholder {
  width: 100%;
  height: 178px;
}
.shimmer.editer-image-placeholder {
  width: 100%;
  height: 200px;
}
.shimmer.text-line.featured-short {
  width: 60%;
  height: 48px;
}
.shimmer.text-line.sub-featured-short {
  width: 60%;
  height: 34px;
}
.shimmer.text-line.featured-title {
  height: 132px;
  width: 80%;
  margin-top: 2px;
}
.shimmer.text-line.editer-title {
  height: 80px;
  width: 80%;
  margin-top: 3px;
}
.shimmer.text-line.editer-head {
  height: 24px;
  width: 100%;
}
.shimmer.text-line.sub-featured-title {
  height: 110px;
  width: 80%;
  margin-top: 2px;
}
.sub-featured-shimmer-wrapper {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  overflow: hidden;
}
.editer-shimmer-wrapper {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  overflow: hidden;
}
.editer-shimmer-box {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}
@keyframes shimmer {
  0% {
      transform: translateX(-250%);
  }
  100% {
      transform: translateX(100%);
  }
}
