.ffErrorPageContent {
  width: 100%;
  background: #731546; 
  padding-top: 100px;
  position: relative;
  min-height: 910px;
  padding-left: 64px;
  @media (max-width:1200px) {
    min-height: auto;
    padding-left: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  img {
    width: 820px;
    height: 694px;
    flex-shrink: 0; 
    position: absolute;
    right: 16px;
    bottom: 0;
    @media (max-width:1200px) {
      width: 356.522px;
      height: 301.739px;
      flex-shrink: 0; 
      position: static;
    }
  }
  h1 {
    color: #FFF;
    font-size: 350px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 30px;
    width: 100%;
    @media (max-width:1200px) {
      font-size: 170px;
      font-style: normal;
      font-weight: 800; 
      line-height: normal; 
      text-align: center;
      margin-bottom: 20px;
    }
  }
  p {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
    width: 100%;
    @media (max-width:1200px) {
      font-size: 32px; 
      text-align: center;
      margin-bottom: 20px;
    }
  }
  a {
    border-radius: 100px;
    border: 2px solid #FFF;
    background: #ca257b;
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    padding: 24px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    @media (max-width:1200px) {
      padding: 18px 60px;
      font-size: 30px;
    }
  }
}