.ffFooterSection {
    width: 100%;
    .ffFooterTop {
        width: 100%;
        background: #ca257b;
        padding-top: 24px;
        padding-bottom: 21px;
        @media (max-width:991px) {
          padding-top: 10px;
          padding-left: 8px;
          padding-right: 8px;
        }
        .ffFooterTopRow {
            width: 100%;
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 50px;
            @media (max-width:991px) {
                flex-wrap: wrap;
                gap: 10px;
            }
            .ffFooterTopTitle {
              color: #FFF;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              display: block;
              margin-bottom: 20px;
              padding-bottom: 11px;
              position: relative;
              svg {
                @media (min-width:992px) {
                  display: none;
                }
              }
              @media (min-width:992px) {
                pointer-events: none;
              }
              @media (max-width:991px) {
                margin-bottom: 0;
                height: 44px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: space-between;
                padding-bottom: 0;
                width: 100%;
                cursor: pointer;
              }
              &:before {
                content: "";
                position: absolute;
                left: 0;
                width: 65px;
                height: 1px;
                background: #808080;
                bottom: 0;
                @media (max-width:991px) {
                  display: none;
                }
              }
            }
            .ffFooterTopList {
                display: flex;
                flex-wrap: wrap;
                flex-shrink: 0;
                &:nth-child(2) {
                  width: 200px;
                  @media (max-width:991px) {
                    width: 100%;
                    margin: 0;
                  }
                }
                &:nth-child(3) {
                  width: 160px;
                  margin-right: 25px;
                  @media (max-width:991px) {
                    width: 100%;
                    margin: 0;
                  }
                }
                &:nth-child(4) {
                  width: 145px;
                  @media (max-width:991px) {
                    width: 100%;
                    margin: 0;
                  }
                }
                &:nth-child(5) {
                  width: 80px;
                  @media (max-width:991px) {
                    width: 100%;
                    margin: 0;
                  }
                }
                @media (max-width:991px) {
                    text-align: center;
                }
                .ffFooterTopListInner {
                  a {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 100%;
                    display: block;
                    margin-bottom: 10px;
                    text-align: left;
                  }
                  @media (max-width:991px) {
                    display: none;
                  }
                }
                
            }
            .nav_mobile {
              &.active {
                .ffFooterTopTitle {
                  svg {
                    transform: rotate(-90deg);
                  }
                }
                .nav_mobile_inner {
                  display: block;
                }
              }
            }
        }
        .ffFooterTopDesc {
            width: 100%;
            padding-top: 26px;
            display: flex;
            justify-content: center;
        }
    }
    .ffFooterBottom {
        background: #1A002A;
        width: 100%;
        padding-bottom: 12px;
        padding-top: 12px;
        p {
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
    }
}

  .nav_col{
      flex-shrink: 0;
      margin-right: 40px;
      @media (max-width:991px) {
        margin-right: 0;
        width: 100%;
      }
  }
  .nav_list {
    @media (max-width:991px) {
      display: none;
    }
  }
  .footer_logos_list {
    transition-duration:.15s;
    transition-property:all;
    transition-timing-function:cubic-bezier(.4,0,.2,1);
    list-style-type:none;
    display:grid;
    grid-template-columns:repeat(4,minmax(0,1fr));
    align-items:center;
    grid-column-gap:.5rem;
    column-gap:.5rem;
    grid-row-gap:.5rem;
    row-gap:.5rem;
    padding:0
  }
  @media screen and (max-width:991px) {
    .footer_logos_list {
      padding:0;
    }
  }
  .footer_logos_list_item {
    display:flex;
    justify-content:center
  }
 